<template>
  <div class="aboutus bg-white">
    <div class="back py-5">

      <div class="container py-5">
        <div class="row"  data-aos="fade-in" data-aos-duration="2200">
          <div class="col-8 mx-auto text-center">
            <p class="display-1 mb-1 text-white"><i class="fad fa-users"></i></p>
            <h1 class="text-white">Sobre nosotros</h1>
            <h5 class="text-white mb-5">Somos una empresa orgullosamente Mexicana, con un equipo de colaboradores altamente capacitado, comprometido y con un gran gusto por el servicio a nuestros clientes.</h5>
          </div>
        </div>
      </div>

      <div class="container py-3 mb-5">
        <div class="row text-white">
          <div class="col-sm-2 text-center mt-2" data-aos="fade-up" data-aos-duration="1200">
            <p class="display-4"><i class="fad fa-hand-holding-box"></i></p>
            <p>Entregas Inmediatas</p>
          </div>
          <div class="col-sm-2 text-center mt-2" data-aos="fade-up" data-aos-duration="1400">
            <p class="display-4"><i class="fad fa-shipping-timed"></i></p>
            <p>Entrega oportuna en tiempo y forma</p>
          </div>
          <div class="col-sm-2 text-center mt-2" data-aos="fade-up" data-aos-duration="1800">
            <p class="display-4"><i class="fad fa-badge-check"></i></p>
            <p>Calidad de primera en nuestros Productos</p>
          </div>
          <div class="col-sm-2 text-center mt-2" data-aos="fade-up" data-aos-duration="2200">
            <p class="display-4"><i class="fad fa-user-hard-hat"></i></p>
            <p>Asesoria</p>
          </div>
          <div class="col-sm-2 text-center mt-2" data-aos="fade-up" data-aos-duration="2600">
            <p class="display-4"><i class="fad fa-handshake"></i></p>
            <p>Excelente trato</p>
          </div>
          <div class="col-sm-2 text-center mt-2" data-aos="fade-up" data-aos-duration="3000">
            <p class="display-4"><i class="fad fa-dollar-sign"></i></p>
            <p>Precios accesibles</p>
          </div>
        </div>
      </div>

      <div class="container py-5" v-if="type == 'true'">

        <div class="row d-flex justify-content-between mb-5" data-aos="fade-in" data-aos-duration="2200">
          
          <div class="col-5 mx-auto text-center">
            <h1 class="text-white mb-0">Misión</h1>
            <h5 class="text-white">Ofrecer mediante el servicio de comercialización y distribución de acero, valores que impulsen el bienestar y crecimiento social.</h5>
          </div>

          <div class="col-5 mx-auto text-center">
            <h1 class="text-white mb-0">Visión</h1>
            <h5 class="text-white">Consolidarnos como la mejor opción, superando las expectativas y aportando la mejor experiencia a nuestros clientes.</h5>
          </div>

        </div>

        <div class="row d-flex justify-content-between py-5" data-aos="fade-in" data-aos-duration="2200">
          
          <div class="col-12 mx-auto text-center">
            <h1 class="text-white mb-4">Valores</h1>
            <h5 class="text-white text-center">
              Respeto
              <span class="px-3">-</span>
              Honestidad
              <span class="px-3">-</span>
              Responsabilidad
              <span class="px-3">-</span>
              Lealtad
              <span class="px-3">-</span>
              Humildad
            </h5>
          </div>

        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: [ 'type' ]
}
</script>

<style scoped>
.aboutus {
  background-image: url('../assets/nosotros.jpg');
  background-size: cover;
  background-repeat: none;
}

.back {
  background-color: black;
  opacity: .8;
}
</style>